
import { dispatchGetBrandDetail, dispatchGetPopularKindsProductsList } from '@/store/products/actions';
import { Component, Vue } from 'vue-property-decorator';
import { ICreatureKind, IProductBrand } from '@/interfaces/products';

@Component({
  components: {
    // ProductGridCard,
  },
})

export default class BrandView extends Vue {
  public popularKindsProductsList: ICreatureKind[] = [];
  public perPage = 10;
  public page = 1;
  public rating = 0;
  public brand: IProductBrand | null = null;
  public loadingState: any = {
    page: true,
    list: false,
  };

  public async mounted() {
    this.brand = await dispatchGetBrandDetail(this.$store, {codename: this.$router.currentRoute.params.codename});
    this.popularKindsProductsList = await dispatchGetPopularKindsProductsList(this.$store, {brand: this.$router.currentRoute.params.codename});
    this.loadingState.page = false;
  }

  public handleChangePopularKind(id) {
    this.$router.push(`/products/kinds/list/${id}/?brand=${this.$router.currentRoute.params.codename}`);
  }

  // public async handleChangePage(page, force = false) {
  //   if (this.page != page || force) {
  //     this.loadingState.list = true;
  //     this.page = page;
  //     const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;
  //     await dispatchGetProductsList(
  //       this.$store, {data: {
  //         brand: this.$router.currentRoute.params.codename,
  //       }, rowQuery},
  //     );
  //     this.loadingState.list = false;
  //   }
  // }

  // get productsResponse() {
  //   return readProductsList(this.$store);
  // }
}
